<template>
  <div>
    <Header />
    <section class="bg-light d-none d-lg-block">
      <div class="container py-4">
        <div class="bg-white p-4 rounded-10">
          <div class="d-flex justify-content-start w-100 gap-3">
            <img
              :src="nftDetails.image_url"
              class="rounded-10"
              width="230"
              height="230"
              alt=""
            />

            <div class="p-3 w-100">
              <h3 class="fw-bold">
                {{ nftDetails.collection?.name }} #{{ nftDetails.token_id }}
              </h3>
              <div class="d-flex justify-content-start gap-2">
                <a
                  :href="nftDetails.collection?.external_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-global-line"></em
                ></a>
                <a
                  :href="nftDetails.collection?.chat_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-message-line"></em
                ></a>
                <a
                  :href="nftDetails.collection?.discord_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-discord-line"></em
                ></a>
                <a
                  :href="
                    'https://instagram.com/' +
                    nftDetails.collection?.instagram_username
                  "
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-instagram-line"></em
                ></a>
                <a
                  :href="
                    'https://medium.com/@' +
                    nftDetails.collection?.medium_username
                  "
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-medium-line"></em
                ></a>
                <a
                  :href="
                    'https://twitter.com/' +
                    nftDetails.collection?.twitter_username
                  "
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-twitter-line"></em
                ></a>
                <a
                  :href="nftDetails.collection?.telegram_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-telegram-line"></em
                ></a>
                <a
                  :href="nftDetails.collection?.wiki_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-search-line"></em
                ></a>
              </div>
              <div class="d-flex justify-content-start gap-2">
                <div>Owned By:</div>
                <a>{{ nftDetails.owner?.address }}</a>
              </div>
              <div class="row mt-3">
                <div class="col-3">
                  <div class="card">
                    <div class="card-body">
                      <div
                        class="d-flex flex-column justify-content-start gap-3"
                      >
                        <div>Last sale price</div>
                        <div>
                          {{ nftMetrics.lastSalePrice?.priceInCrypto?.price }}
                          {{
                            nftMetrics.lastSalePrice?.priceInCrypto?.coinName
                          }}
                          ({{
                            nftMetrics.lastSalePrice?.priceInFiat?.current
                              ?.price
                          }})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="card">
                    <div class="card-body">
                      <div
                        class="d-flex flex-column justify-content-start gap-3"
                      >
                        <div>Floor price</div>
                        <div>
                          {{ nftMetrics.floorPrice?.priceInCrypto?.price }}
                          {{
                            nftMetrics.floorPrice?.priceInCrypto?.coinName
                          }}
                          ({{ nftMetrics.floorPrice?.priceInFiat?.price }})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="card">
                    <div class="card-body">
                      <div
                        class="d-flex flex-column justify-content-start gap-3"
                      >
                        <div>Estimated price</div>
                        <div>
                          {{
                            Number(
                              nftMetrics.estimatedPrice?.priceInCrypto?.price
                            ).toFixed(2)
                          }}
                          {{
                            nftMetrics.estimatedPrice?.priceInCrypto?.coinName
                          }}
                          ({{ nftMetrics.estimatedPrice?.priceInFiat?.price }})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="card">
                    <div class="card-body">
                      <div
                        class="d-flex flex-column justify-content-start gap-3"
                      >
                        <div>Owner</div>
                        <div>{{ nftDetails.asset_contract?.owner }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h5 class="my-4 fw-bold">Payments Token</h5>
          <div class="row">
            <div
              :class="indexPayments > 3 ? 'col-3 mt-4' : 'col-3'"
              v-for="(payments, indexPayments) in nftDetails.collection
                ?.payment_tokens"
              :key="indexPayments"
            >
              <div class="card">
                <div class="card-body">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      gap-3
                      align-items-center
                    "
                  >
                    <img
                      :src="payments.image_url"
                      class="img-fluid"
                      height="30"
                      width="30"
                      alt=""
                    />
                    <div>
                      <h6 class="fw-bold">{{ payments.name }}</h6>
                      <p class="mb-0">{{ payments.symbol }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h5 class="my-4 fw-bold">Metadata</h5>
          <div class="row">
            <div
              :class="indexMeta > 3 ? 'col-3 mt-4' : 'col-3'"
              v-for="(metadata, indexMeta) in nftMetrics.metaData"
              :key="indexMeta"
            >
              <div class="card">
                <div class="card-body text-center">
                  <h6 class="fw-bold">{{ metadata.label }}</h6>
                  <p class="mb-0">{{ metadata.value }}</p>
                </div>
              </div>
            </div>
          </div>

          <h5 class="my-4 fw-bold">Latest Transaction</h5>
          <div class="card">
            <div class="card-body">
              <table class="table table-responsive table-striped">
                <thead>
                  <tr>
                    <td>Action</td>
                    <td>Date</td>
                    <td>From</td>
                    <td>To</td>
                    <td>Price</td>
                    <td>Quantity</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(transaction, txIndex) in nftTransaction.data"
                    :key="txIndex"
                  >
                    <td>
                      <div class="text-capitalize">{{ transaction.type }}</div>
                    </td>
                    <td>
                      <div>{{ moment(transaction.date).fromNow() }}</div>
                    </td>
                    <td>
                      <div>{{ transaction.from | truncate(6, "...", 50) }}</div>
                    </td>
                    <td>
                      <div>{{ transaction.to | truncate(6, "...", 50) }}</div>
                    </td>
                    <td>
                      <div>
                        {{ transaction.priceInCrypto?.price }}
                        {{ transaction.priceInCrypto?.coinName }}
                        <span v-if="transaction.priceInFiat != null">
                          ({{ transaction.priceInFiat?.currencySymbol
                          }}{{ transaction.priceInFiat?.price }})
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>{{ transaction.quantity }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h5 class="my-4 fw-bold">Creator</h5>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-start gap-3">
                <img
                  :src="nftDetails.collection?.image_url"
                  width="80"
                  height="80"
                  alt=""
                />
                <div>
                  <h5 class="fw-bold">
                    {{ nftDetails.creator?.user?.username }}
                  </h5>
                  <p>{{ nftDetails.asset_contract?.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="d-lg-none d-block px-2 mb-3">
      <div class="card margin-t-10 border-0">
        <div class="card-body">
          <div class="d-flex justify-content-start gap-2">
            <img
              :src="nftDetails.image_url"
              class="rounded-10"
              width="65"
              height="65"
              alt=""
            />

            <div class="p-1 w-100">
              <h3 class="fw-bold">
                {{ nftDetails.collection?.name }} #{{ nftDetails.token_id }}
              </h3>
              <div class="d-flex justify-content-start gap-2">
                <a
                  :href="nftDetails.collection?.external_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-global-line"></em
                ></a>
                <a
                  :href="nftDetails.collection?.chat_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-message-line"></em
                ></a>
                <a
                  :href="nftDetails.collection?.discord_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-discord-line"></em
                ></a>
                <a
                  :href="
                    'https://instagram.com/' +
                    nftDetails.collection?.instagram_username
                  "
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-instagram-line"></em
                ></a>
                <a
                  :href="
                    'https://medium.com/@' +
                    nftDetails.collection?.medium_username
                  "
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-medium-line"></em
                ></a>
                <a
                  :href="
                    'https://twitter.com/' +
                    nftDetails.collection?.twitter_username
                  "
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-twitter-line"></em
                ></a>
                <a
                  :href="nftDetails.collection?.telegram_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-telegram-line"></em
                ></a>
                <a
                  :href="nftDetails.collection?.wiki_url"
                  class="text-decoration-none h4 text-dark"
                  ><em class="ri-search-line"></em
                ></a>
              </div>
              <div class="d-flex justify-content-start gap-2">
                <div>Owned By:</div>
                <a>{{ nftDetails.owner?.address | truncate(6, "...", 50) }}</a>
              </div>
            </div>
          </div>

          <div
            class="d-flex justify-content-start gap-3 w-100 custom-scroll mt-3"
          >
            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start gap-3">
                    <div>Last sale price</div>
                    <div>
                      {{ nftMetrics.lastSalePrice?.priceInCrypto?.price }}
                      {{
                        nftMetrics.lastSalePrice?.priceInCrypto?.coinName
                      }}
                      (${{
                        nftMetrics.lastSalePrice?.priceInFiat?.current?.price
                          | formatNumber
                      }})
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start gap-3">
                    <div>Floor price</div>
                    <div>
                      {{ nftMetrics.floorPrice?.priceInCrypto?.price }}
                      {{ nftMetrics.floorPrice?.priceInCrypto?.coinName }} (${{
                        nftMetrics.floorPrice?.priceInFiat?.price
                          | formatNumber
                      }})
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start gap-3">
                    <div>Estimated price</div>
                    <div>
                      {{
                        Number(
                          nftMetrics.estimatedPrice?.priceInCrypto?.price
                        ).toFixed(2)
                      }}
                      {{
                        nftMetrics.estimatedPrice?.priceInCrypto?.coinName
                      }}
                      (${{
                        nftMetrics.estimatedPrice?.priceInFiat?.price
                          | formatNumber
                      }})
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start gap-3">
                    <div>Owner</div>
                    <div>{{ nftDetails.asset_contract?.owner }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h5 class="my-4 fw-bold">Payments Token</h5>
          <div class="d-flex justify-content-start gap-3 w-100 custom-scroll">
            <div
              :class="indexPayments > 3 ? 'col-8 mt-4' : 'col-8'"
              v-for="(payments, indexPayments) in nftDetails.collection
                ?.payment_tokens"
              :key="indexPayments"
            >
              <div class="card">
                <div class="card-body">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      gap-3
                      align-items-center
                    "
                  >
                    <img
                      :src="payments.image_url"
                      class="img-fluid"
                      height="30"
                      width="30"
                      alt=""
                    />
                    <div>
                      <h6 class="fw-bold text-nowrap">{{ payments.name }}</h6>
                      <p class="mb-0">{{ payments.symbol }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h5 class="my-4 fw-bold">Metadata</h5>
          <div class="d-flex justify-content-start gap-3 w-100 custom-scroll">
            <div
              :class="indexMeta > 3 ? 'col-8 mt-4' : 'col-8'"
              v-for="(metadata, indexMeta) in nftMetrics.metaData"
              :key="indexMeta"
            >
              <div class="card">
                <div class="card-body text-center">
                  <h6 class="fw-bold">{{ metadata.label }}</h6>
                  <p class="mb-0">{{ metadata.value }}</p>
                </div>
              </div>
            </div>
          </div>

          <h5 class="my-4 fw-bold">Latest Transaction</h5>
          <div class="table-responsive">
            <table class="table table-responsive table-striped">
              <thead>
                <tr>
                  <td>Action</td>
                  <td>Date</td>
                  <td>From</td>
                  <td>To</td>
                  <td>Price</td>
                  <td>Quantity</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transaction, txIndex) in nftTransaction.data"
                  :key="txIndex"
                >
                  <td>
                    <div class="text-capitalize">{{ transaction.type }}</div>
                  </td>
                  <td>
                    <div class="text-nowrap">
                      {{ moment(transaction.date).fromNow() }}
                    </div>
                  </td>
                  <td>
                    <div>{{ transaction.from | truncate(6, "...", 50) }}</div>
                  </td>
                  <td>
                    <div>{{ transaction.to | truncate(6, "...", 50) }}</div>
                  </td>
                  <td>
                    <div>
                      {{ transaction.priceInCrypto?.price }}
                      {{ transaction.priceInCrypto?.coinName }}
                      <span v-if="transaction.priceInFiat != null">
                        ({{ transaction.priceInFiat?.currencySymbol
                        }}{{ transaction.priceInFiat?.price }})
                      </span>
                    </div>
                  </td>
                  <td>
                    <div>{{ transaction.quantity }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h5 class="my-4 fw-bold">Creator</h5>
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-start gap-3">
                <img
                  :src="nftDetails.collection?.image_url"
                  width="80"
                  height="80"
                  alt=""
                />
                <div>
                  <h5 class="fw-bold">
                    {{ nftDetails.creator?.user?.username }}
                  </h5>
                  <p>{{ nftDetails.asset_contract?.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import moment from "moment";

export default {
  name: "NftDetails",
  data() {
    return {
      nftDetails: [],
      nftMetrics: [],
      nftTransaction: [],
      moment: moment,
    };
  },
  mounted() {
    this.getNftDetails();
    this.getNftDetailsMetrics();
    this.getNftTransaction();
  },
  methods: {
    getNftDetails() {
      axios
        .get(
          "https://api.opensea.io/api/v1/asset/" +
            this.$route.params.address +
            "/" +
            this.$route.params.id +
            "?"
        )
        .then((res) => {
          this.nftDetails = res.data;
        });
    },
    getNftDetailsMetrics() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/metaservice/api/nft-metadata/ethereum/" +
            this.$route.params.address +
            "/" +
            this.$route.params.id +
            "?currency=USD&fiat=USD"
        )
        .then((res) => {
          this.nftMetrics = res.data;
        });
    },
    getNftTransaction() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/balance/transactions/ethereum/" +
            this.$route.params.address +
            "/" +
            this.$route.params.id +
            "?page=1&resultsPerPage=25&fiat=USD"
        )
        .then((res) => {
          this.nftTransaction = res.data;
        });
    },
  },
  components: { Header, Footer },
};
</script>

<style>
.custom-scroll {
  overflow-x: auto;
}
.custom-scroll::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none;
}
/* Optional: show position indicator in red */
.custom-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>